/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "functions";

$grid-breakpoints: (
  xs: 0,
  md: 768px,
  lg: 1024px
);

$font-family-base: 'Montserrat', sans-serif;
$font-weight-base: 400;

$primary: #C11717;
$nav-link-color: #FFFFFF;

// Create your own map
$custom-colors: (
    "bg-body": #101010
);

@import "variables";
@import "maps";
@import "mixins";
@import "utilities";

$theme-colors: map-merge($theme-colors, $custom-colors);

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
//@import "button-group";
@import "nav";
@import "navbar";
/*
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
*/
@import "close";
//@import "toasts";
@import "modal";
/*
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";
*/
// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

.font-300 {
    font-weight: 300 !important;
}

.font-400 {
    font-weight: 400 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.font-600 {
    font-weight: 600 !important;
}

.font-700 {
    font-weight: 700 !important;
}

.font-800 {
    font-weight: 800 !important;
}

.font-900 {
    font-weight: 900 !important;
}

.mb-100 {
    margin-bottom: 100px;
}

.mt-100 {
    margin-top: 100px;
}

.ms-100 {
    margin-left: 100px;
}

.me-100 {
    margin-right: 100px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mt-50 {
    margin-top: 50px;
}

.ms-50 {
    margin-left: 50px;
}

.me-50 {
    margin-right: 50px;
}

.form-control,
.form-select,
.form-control:focus,
.form-select:focus {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    padding: 12px 0;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 1.5vw;
    letter-spacing: 0.08em;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.4);
    outline: 0;
    box-shadow: none;
}

.form-select {
    color: #fff;
    background-position: right 0rem center;
    background-size: 17px 22px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 17'%3e%3cpath fill='%23101010' d='m.652344.584106 21.011556.099636-10.4725 15.669958z'/%3e%3c/svg%3e");
}

.form-control.dark,
.form-select.dark,
.form-control.dark:focus,
.form-select.dark:focus {
    font-weight: 600;
    border: 1px solid #fff;
    color: #fff;
    font-size: 1vw;
    background-color:#101010;
}

.form-control.dark {
    padding: 12px 18px 12px 18px;
}

.form-select.dark {
    padding: 12px 36px 12px 18px;
    background-position: right 24px center;
    background-size: 17px 22px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 17'%3e%3cpath fill='%23ffffff' d='m.652344.584106 21.011556.099636-10.4725 15.669958z'/%3e%3c/svg%3e");
}

.form-group {
    margin-bottom: 2vw;
}

.btn-primary {
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.03em;
    font-size: 1.5vw;
    border-radius: 0;
    padding: 1vw 2vw;
    box-shadow: none;
    outline: 0;
}

@media (max-width: 767.98px) {
    .form-control,
    .form-select,
    .form-control:focus,
    .form-select:focus {
        font-size: 3vw;
    }
    .form-control.dark,
    .form-select.dark,
    .form-control.dark:focus,
    .form-select.dark:focus {
        font-size: 2vw;
    }
    .form-group {
        margin-bottom: 4vw;
    }
    .btn-primary {
        font-size: 3vw;
        border-radius: 0;
        padding: 2vw 4vw;
    }
}
